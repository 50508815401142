.module.module--articles {
    .module__content { 
        &--articles {
            display: flex;
            justify-content: center;
            .layout {
                &.layout--tiles{
                    @media screen and ( max-width: 1080px ) {
                        justify-content: space-between;
                    }
                    @media screen and ( max-width: 580px ) {
                        justify-content: center;
                    }
                }
            }
            .articles {
                .article {
                    flex-basis: 0;
                    flex-grow: 1;
                    min-width: 260px;
                    max-width: 260px; 
                    margin: 0 8px; 
                    &__container {
                        border: 1px solid @borderColor2;
                        border-radius: 5px;
                        min-height: 365px;
                    } 
                    &__thumbnail { 
                        max-width: 260px; 
                        height: 170px; 
                        border-radius: 5px; 
                        background-repeat: no-repeat;
                        background-size: cover;
                         > img {
                             margin-top: -1px;
                             display: none;
                         }
                    } 
                    &__content { 
                        padding: 0 1rem 1rem 1rem;

                        .article__header {
                            padding: 0;
 
                            .article__title {
                                font-size: 16px;
                                font-weight: normal;
                                line-height: 1.2;
                            }
                        }
 
                        .article__excerpt {  
                            font-size: 13px;
                            font-weight: 300;
                            line-height: 1.538;  
                            .article__read-more {
                                text-align: right;
                                color: @mainColor;
                                font-weight: bold;
                                padding-right: 5px;

                                position: relative;  

                                &::after {  
                                    content: '\e912';
                                    font-family: Frino;  
                                    color: @mainColor; 
                                    position: absolute; 
                                    top: 1px;
                                    right: -10px;
                                }
                            }
                        }
                    }
                }
            }
              
        }

    }
    .module__footer { 
        text-align: right;

        padding: 1rem;

        .button {
            font-size: 1rem; 
            font-weight: 700;
            text-transform: uppercase;
            padding: 0.9rem 2rem 1.1rem; 
        }
    }
}



/**********NEWS LIST**********/

.articles.articles--blog {
    .article {
        &__container {
            .article__thumbnail {
                background-repeat: no-repeat;
            }

            .article__content {
                .article__footer {
                    margin-bottom: 0;
                }
                .article__header {
                    padding: 0;
                }
                .article__excerpt {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
.module.module--about {
    flex-basis: 35%;
    .module__content {
        padding: 2rem 1rem 2rem 2.5rem; 
        text-align: justify;
        max-height: 175px;
        overflow: hidden;
        transition: 1s;  
        display: block;

        &.expanded {
            max-height:999px;
            transition: 1s;  
        }
        p {
            font-size: 12px;
            line-height: 1.667;
            color: rgb(235, 235, 235)!important;
        } 
    }

    .readmore {
        display: flex;
        justify-content: flex-end;
        color: white;
        font-weight: bold;
        padding-bottom: 2rem;
        cursor: pointer;
        transition: .4s ease-in-out;
        &:hover {
            color: @mainColor;
        }
    }
} 
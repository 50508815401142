.product__tags {
    .tags {
        top: 10px!important;
        left: 10px!important;
        > .tag {
            width: 40px; 
            height: 40px; 
            border-radius: 50%;
            color: white; 
            font-size: 12px;
            font-weight: bold;
            line-height: 40px;  
            margin: 0 3px;
            &--sale {
                background: @mainColor;
            }

            &--new {
                background: black; 
            }
        }
    }
}
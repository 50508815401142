.modules.modules--footer {
    padding: 0;
    margin: 0;
    align-items: flex-start;

    .module {
       // flex-basis: 100%;
        width: 100%;
        flex-grow: 1;

        @media screen and ( max-width: 1080px ) {
            flex-basis: 100%;
            margin-bottom: 0;
            text-align: center;
            &--newsletter{
                margin: 0 30px;
                padding-right: 0; 
            }
        }

        .module__content {
            &.module__content--reset-css {
                h1, h2, h3, h4, h5, h6, p {
                    color: white;
                }
                em {
                    color: @mainColor;
                }
            }
        }
    }
}
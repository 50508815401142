.blog-single__content {
    padding: 0 2rem!important;
}

.single-article {
    &__info-wrapper {
        position: relative;
        margin: 1rem -1rem 0 -1rem;
        min-height: 50px;
    }
    &__info {
        position: absolute;
        bottom: 15px;
        left: 10px;

        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .article-date, .article-categories {
            color: white;
            font-size: 16px;
            border-radius: 3px; 
            padding: 5px 25px;
            margin: 0 10px;
            > a {
                color: white;
            }
        }
        .article-date {
            background: @mainColor; 
        }
        .article-categories { 
            background: black;
        }
    }
    &__header {
        .article-name {
            font-size: 2.8em; 
        }
    }
    &__author {
        color: @mainColor;
        font-size: 14px;
        margin: 5px 0 0 0;
        .article-author {
            font-weight: bold;
        }
    }
    .article-content {
        img {
            height: auto;
        }
    }
} 
.module.module--collection-point {
    flex-basis: 30%;
    .module__content {
        border: 1px solid @borderColor;
        border-radius: 5px;
        margin: .6rem 1.5rem;
        padding: 2rem;

        .collection-point {
            .collection-point__top {
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    margin-right: 10px;
                }
                p { 
                    font-size: 14px;

                    strong { 
                        font-size: 16px; 
                    } 
                }
            }
 
            .collection-point__bottom {
                p {
                    font-size: 12px;
                    margin: .7rem 0;
                    text-align: center;
                }

                a {
                    background: @mainColor;
                    color: white;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 1;
                    width: 100%;
                    max-width: 145px;
                    min-height: 35px;
                    border-radius: 5px;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    margin: 0 auto;
                }
            }
        }
    }
}
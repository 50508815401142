.module.module--popup {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    padding: 0; 
    margin: 0;

    display: flex;


    align-items: center;
    justify-content: center;
    flex-basis: 100%;

    background: rgba(255,255,255,.8);

    z-index: 99999;

    //top: -120%;

    display: none;

    transition: .5s ease-in-out;
    


    @media screen and (max-width: 767px) {
        display: none!important;
    }

    .newsletter {
        background: url('../images/user/popup-bg.png') no-repeat center;
        background-color: black;
        background-size: cover;
        min-height: 330px;
        width: 100%;
        max-width: 865px;
        border-radius: 20px;
        padding: 50px;

        display: flex;
        align-items: center;

        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.6);

        position: absolute;
        top: -150%;
        right: 0;
        left: 0;
        margin: 0 auto;

        overflow: hidden;

        transition: 1s ease-in-out;
        &__header {
            flex-basis: 50%;

            padding: 0 0 2rem 0;

            color: white;
            line-height: 1.2;
            .newsletter__title {
                color: white;
                font-size: 3.9rem;
                font-weight: 300;
                line-height: 1.2;
                margin: 0;
            }

            .newsletter__subtitle {
                font-size: 1.9rem;
                font-weight: bold;
            }
        }

        &__form {
            flex-basis: 50%;
            max-width: 360px;
            margin: 0;

            .newsletter__fieldset {
                background: rgba(255,255,255,.8);
                min-height: 45px!important;
            }

            .newsletter__input {
                background: transparent;
                min-height: 45px!important;
                &::placeholder {
                    color: black;
                }
            }
        }

        &__close {
            color: white;
            position: absolute;
            top: -50px;
            right: -50px;
            background: @mainColor;
            width: 115px;
            height: 115px;
            border-radius: 50%;
            transition: .4s ease-in-out;
            > i {
                position: absolute;
                left: 30px;
                bottom: 30px;
            }

            &:hover {
                background: white;
                color: @mainColor;
            }
        }
    }
}
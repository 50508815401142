.credits {
    width: 100%;
    background: white;

    &__wrapper {
        min-height: 75px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
    
        flex: 1 1 calc(100% / 3);

        font-size: 12px;
        @media screen and ( max-width: 718px ) {
            flex-direction: column;
            .credit{
                margin: 10px 0;
                &--blank{
                    display: none;
                }
            }
        }
        .credit--blank, .credit--copyright, .credit--created-by {
            flex-basis: calc(100% / 3);
        }

        .credit--copyright {
            text-align: center;

            .shoper {
                > a {
                    color: rgba(54, 54, 54, 0.6);
                }
            }

              
        }
        .credit--created-by {
            color: black;
            font-weight: 700;
            text-align: right;
            text-decoration: none;
            .credit--link {
                font-size: 12px;
                .logo {
                    &--getecom {
                        display: inline-block;
                        vertical-align: middle;
                        width: 129px;
                        transition: opacity 0.3s ease-in-out;
                    }
                }
            }

        }
    }

}
.module--brands {
    .module__header {
        margin: 1rem 0 2.3rem 0;
    }
    &.module--brands-subpage {
        .brands {
            .brand {
                min-width: 290px;
                width: 290px;
            }
        }
    }
    .brands {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-around;

        .brand {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
     
            //flex-basis: calc((100%/4) - 16px); 
            flex-grow: 1;

            background-repeat: no-repeat!important;
            background-position: right bottom!important;

            position: relative;
            transition: .4s ease-in-out;

            height: 287px;
            min-width: 287px;
            width: 287px;
            border: 1px solid @mainColor; 
            margin: 3px;

           

            &::after {
                content: 'LISTA PRODUKTÓW';
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                color: white;
                font-weight: bold;
                font-size: 18px;

 
                position: absolute;
                top: 0;
                left: 0;
                min-width: 287px;

                background: rgba(242, 125, 0, .8);
                opacity: 0;
                transition: .4s ease-in-out;
            }
            @media screen and (max-width: 979px) {
                flex-basis: calc((100%/2) - 16px);  
            } 
            @media screen and (max-width: 410px) {
                flex-basis: 100%;
            }
            &:hover {
                opacity: 1;
                &::after {
                    opacity: 1;
                }

                .brand__logo {
                    img {
                        filter: invert(100%);
                    }
                }
            }

            &__logo {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                margin: 20px; 
                z-index: 1;
            }
    
            &__button { 
                display: flex; 
                justify-content: center; 
                align-items: center; 
            } 
            
    
        } 
    }
}

.menu {
    color: white;
    margin: 0; 
    padding: 0; 
    z-index: 1;
    #rwd-menu {
        width: 100%;
        background-color: black;
    }
    > .innermenu { 
        height: auto!important;
        max-width: 1185px;
        @media screen and (max-width: 767px) {
            background: black;
            border: 0!important;

            li { 
                a {
                    color: white!important;
                }
            }
        }
        .menu-list {  
            display: flex;
            border-bottom: 0; 
            height: auto;
            margin: 5px 2.5px; 

            > li { 
                background: black;  
                border-bottom: 3px solid @mainColor;    
                margin: 2.5px 5px;  
 
                width: 100%;   
                max-width: 288px;  
                height: 90px; 

                border-top-left-radius: 5px; 
                border-top-right-radius: 5px; 
                transition: .3s ease-in-out;
                cursor: pointer; 
                &:nth-child(2) {
                    margin: 2.5px 3px 2.5px 0;
                }
                &:nth-child(5) {
                    margin: 2.5px 0 2.5px 3px!important;
                }
                &:hover { 
                    background: @mainColor;
                    > h3 {
                        > a {
                            text-decoration: none;   
                            opacity: 1;
                            color: white;
                        }
                    } 
                }

                &.current {
                    background: @mainColor;
                    color: white;
                    a {
                        color: white;
                    }
                }
                > h3 {
                    margin: 0; 
                    width: 100%;
                    text-align: center;
                    > a {  
                        color: white;
                        text-transform: uppercase; 
                        font-weight: bold;
                        width: 100%;
                        height: 90px;
 
                        display: flex;    
                        align-items: center;
                        justify-content: center;  
                        text-align: center;   
                        > img {
                            padding-right: 10px;
                            height: 27px;
                        }

                    }
                }

                &:last-of-type {
                    > h3 {
                        > a {
                            > img {
                                height: 35px;
                            }
                        }
                    }
                }

                .submenu {
                    ul { 
                        border: 0;
                        > li {
                            border-top-left-radius: 3px; 
                            border-bottom-left-radius: 3px;   
                            
                            &.parent {
                                > h3 {
                                    position: relative;
                                    &::before {
                                        content: '\e912';
                                        font-family: Frino; 
                                        position: absolute; 
                                        top: 6px;
                                        right: 10px;
                                        color:  black;
                                    }
                                    &:hover {
                                        &::before {
                                            color: white;
                                        }
                                    }
                                }
                                
                            }
                        }
                    }
                    .level1{
                        top: 98px!important;
                        width: 24.5%; 
                        //left: 0;
                        &::before {
                            display: none;
                        }  
 
                         li { 
                            font-size: 16px;
                            a {  
                                color: black;
                                padding: 0.2em 1.3em;  
                                text-decoration: none;
                            }
                            &:hover {  
                                background: @mainColor;
                                > a {
                                    color: white; 
                                    text-decoration: none;
                                    opacity: 1;
                                }  
                            }
                        } 
  
                        .level2 { 
                            right: -100%!important; 
                            top: 0px!important;

                            &.sub-right {
                                right: 100%!important;
                            }
                            &::before {
                                display: none;
                            }
                            > li { 
                                font-size: 16px;
                                a { 
                                    color: black;
                                    padding: 0.2em 1.3em; 
                                }
                                &:hover { 
                                    background: @mainColor;
                                    > a {
                                        color: white;
                                        text-decoration: none;
                                        opacity: 1;
                                    } 
                                }

                                .level3, .level4, .level5 {
                                    top: 0px!important;
                                    right: -100%!important;
                                    &::before { 
                                        display: none;
                                    }

                                }
                            }

                            
                            
                        }
                    }
                }
            }
        }
    }
} 
 
.shop_index {
    .menu > .innermenu {
        max-width: 1180px;
        .menu-list {
            justify-content: space-between;
            > li {
              margin: 2.5px 2px;  
            }
        }
    }
}
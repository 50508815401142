.tab-container {
    margin: 3rem 0;
    nav {
        ul {
            border: 0;
            padding: 0 3rem;
            position: relative;

            display: flex;

            @media screen and (max-width: 575px) {
                flex-direction: column;
                padding: 0;
            }
            &::before {
                content: '';
                width: 100%;
                height: 1px;
                background: #f27d00;
                position: absolute;
                top: 50%;
                left: 0;
                z-index: -1;
                @media screen and (max-width: 575px) {
                    display: none;
                }
            } 
            li {
                .boxhead { 
                    h3 {
                        margin: 0; 
                        padding: 0 15px 0 15px;
                        font-size: 32px;
                        line-height: 1.2;
                        color: black; 
                        background: white;
                        position: relative;
                        @media screen and (max-width: 767px) {
                            font-size: 22px;
                        }
                    } 
                    &.active {
                        border: 0!important;  

                        h3 { 
                            color: @mainColor;
                        }
                    }
                }
            }
        }
    }

    .product-tabs {
        .product-tab {
            .innerbox { 
                padding: .5rem 5rem;

                @media screen and (max-width: 767px) {
                    padding: .5rem;

                    iframe {
                        width: 90%!important;
                    }
                }

                @media screen and (max-width: 480px) {
                    iframe {
                        width: 100%!important;
                        height: auto!important;
                    }
                }

                .resetcss { 
                    p { 
                       font-size: 16px;
                       line-height: 1.45;  
                    }
                    img {
                        //border-radius: 25px;
                        max-width: 800px;
                        height: auto;
                        @media screen and (max-width: 979px) {
                            max-width: 100%;
                        }
                    }
                }
            }
            
            .product-attributes {
                table.table {
                    margin: 1rem 0;
                    td {
                        padding: 10px;
                        &:first-child {
                            background-color: @bgColor;
                            border-bottom: 1px solid  @borderColor3;
                        }
                    }
                }
            }
        }
    }
}
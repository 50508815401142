.breadcrumbs {
    padding: 0;
    transition: .3s ease-in-out;
    .innerbreadcrumbs { 
        text-overflow: ellipsis; 
        padding: 15px 3px; 
        max-width: 1175px;
        border-bottom: 1px solid @borderColor3;
        .breadcrumb-home {
            display: none; 
 
            img {  
                display: none; 
            } 
        }  

        .path { 
            display: flex;
            align-items: baseline;
            flex-wrap: wrap;
            li { 
                padding-left: 0;
                padding-right: 0;   
                display: inline-block; 
                font-size: 14px;
                &.bred-1 {
                    order: -1;
                    .raq {
                        display: none;
                    }
                }
                &.last {
                    font-weight: bold;  
                    color: @mainColor;
                    .raq {
                        color: black;
                    }
                } 

                span { 
                    &.raq {
                        font-weight: normal;
                        margin: 0 2px 0;
                    }
                }
            }
        }
    }
}

.blog-list {
    .blog-list__menu {
        padding: 3rem 1rem 0!important;
        > .box {
            > .boxhead {
                > span {
                    font-size: 24px;
                }
            }
            > .innerbox {
                > .standard {
                    > li {
                        padding: 5px 20px;
                        display: flex;
                        flex-direction: column;
                        border-radius: 3px;
                        &:hover {
                            background:  @bgColor;
                            a {
                                opacity: 1;
                            }
                        }
                        > a {
                            color: black;
                            font-size: 15px;
                        }
                    }
                }

                .tagcloud {
                    > li {
                        padding: 0 1px!important;
                        margin: 5px 0;
                        > a {
                            font-size: 10px!important;
                            font-weight: bold;
                            color: @mainColor;
                            text-transform: uppercase;
                            border: 1px solid @mainColor;
                            border-radius: 3px;
                            padding: 5px 8px;
                        }
                    }
                }
            }
        }
    }

    .blog-list__table {
        .articles--blog {
            margin: 2rem -2.5rem 0 0rem!important;
            @media screen and (max-width: 1280px) {
                margin: 2rem 0 0 0!important;
            }
            .article {
                max-width: 260px;
              
                flex-basis: calc((100% / 3) - 1rem);
                margin: 0 2rem 2.5rem 2rem;
                padding: 0;

                @media screen and (max-width: 1280px) {
                    margin: 0 .5rem 2.5rem .5rem;
                }
                @media screen and (max-width: 979px) {
                    max-width: 100%;
                    margin: 0 5px 2.5rem 5px;
                    flex-basis: calc((100% / 2) - 10px);
                }
                @media screen and (max-width: 480px) {
                    flex-basis: calc(100% / 1);
                }

                .article__link-wrap {
                    display: flex;
                    border: 1px solid  @borderColor2;
                    border-radius: 3px;
                    &:hover {
                        border-color: @mainColor;
                        color: black;
                        opacity: 1;
                    }
                }

                &__container {
                    width: 100%;
                    position: relative;
                    .article__thumbnail {
                        height: 170px;
                        background-size: cover;
                        background-position: center center;
                        border-radius: 3px;
                        > img {
                            display: none;
                        }
                    }
                    .article__timestamp {
                        position: absolute;
                        top: 140px;
                        left: 15px;
                        color: white;
                        background: @mainColor;
                        border-radius: 2px;
                        padding: 3px 12px;
                    }
                    .article__content {
                        padding: 15px 10px;
                        .article__header {
                            .article__title {
                                font-size: 16px;
                            }
                        }
                        .article__excerpt {
                            font-size: 13px;
                            line-height: 1.538;
                        }
                        .article__read-more {
                            text-align: right; 
                            > span {
                                color: @mainColor;
                                font-weight: bold;
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                line-height: 1;
                            }
                        }
                    }
                }
            }

        }
    }
}
.bar {
    display: block;
    width: 100%;
    margin: 0;
    padding: 15px 0;
    @media screen and (max-width: 1200px) {
        padding: 5px 0;
    }
    @media screen and (max-width: 767px){
        padding: 0;
    }
    @media screen and (max-width: 480px){
        display: none;
    }
    &.bar--full-width {
        max-width: 100%;
    }
    &.bar--dark {
       color:  white;
       background: black;
        a {
          color: white;
        }
        .action__icon {
            color: @mainColor; 
        }
        
    }
    .bar__info {
        position: relative; 
        z-index: 1; 

        display: flex;
        justify-content: space-between; 
        align-content: center;

        //margin: 0 -1rem;
        @media screen and (max-width: 1200px) {
            padding:  0 20px;
            flex-direction: column;
            align-items: center;
        }
        @media screen and (max-width: 767px){
            flex-wrap: wrap;
            justify-content: center; 
        }

        &--left {
            display: flex;
            align-items: center;
            @media screen and (max-width: 1200px) {
                padding: 0 0 10px 0;
            }
            @media screen and (max-width: 767px) {
                flex-direction: column;
            }
        }
        .actions {
            &--company { 
                @media screen and (max-width: 767px){
                    flex-basis: 90%;
                    margin: 10px 0;
                    .actions__container{
                        .action{
                            border-right: none;
                        }
                    }
                }
                .actions__container {
                    display: flex;
                    align-items: center; 
                    @media screen and (max-width: 767px){
                        justify-content: center;   
                    }
                    @media screen and (max-width: 480px){
                        flex-direction: column;  
                    }
                    .action {
                        font-size: 14px;  
                        padding: 2px 15px; 
                        border-right: 1px solid rgba(229, 229, 229, .5);
                        @media screen and (max-width: 979px) {
                            font-size: 12px;
                        }
                        > a {
                            display: flex;
                            align-items: center;   
 
                            font-weight: bold;  
                            .action__icon {
                                font-size: 20px;
                                margin-right: 5px;
                            }
                        }
                    }
                }
            } 
              
            &--social {
                .actions__container { 
                    display: flex;
                    align-items: center; 
                    min-height: 30px;
                    margin: 0 5px;
                    .action--social {
                        margin: 0 .7rem;
                        > a {
                            background: @mainColor; 
                            padding: 5px 15px;
                            border-radius: 2px;
                            font-weight: bold; 
                            > .action__icon {
                                color: white;
                            }

                            &.action__instagram {
                                > .action__icon {
                                    &::before {
                                        font-weight: bold;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
 
            &--user { 
                @media screen and (max-width: 767px){
                    display: none;
                    // flex-basis: 50%;
                    // margin: 10px 0;
                }
                .actions__container { 
                    display: flex;
                    align-items: center;
                    @media screen and (max-width: 767px){
                        justify-content: center;    
                    }
                    @media screen and (max-width: 480px){ 
                        flex-direction: column;
                    }
                    .action {
                        font-size: 11px;
                        padding: 5px 15px;
                        > a {
                            border: 1px solid white;
                            border-radius: 25px;
                            padding: 7px 25px;
                            text-transform: uppercase; 
                            font-weight: bold; 
                            line-height: 1; 

                            &.action__log-in, &.action__log-out {
                                border-color: @mainColor;
                                color: @mainColor;
                            } 
                        }

                        &--reg {
                            padding: 5px;
                        }
                        @media screen and (max-width: 480px){
                            margin: 10px 0;
                        }
                    }
                }
            }
        }
    }
}
.links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    @media screen and ( max-width: 718px ) {
       // justify-content: flex-end;
    }
    @media screen and ( max-width: 400px ) {
        justify-content: space-around;
    }
    color: white;

    border-top: 1px solid @borderColor;

    .links__group {
        flex-basis: 1;
        margin: 2rem 0 1.5rem;
    
        &.links__group--4 {
            @media screen and ( max-width: 718px ) {
                flex-basis: 40%;
            }
            @media screen and ( max-width: 400px ) {
                flex-basis: 70%;
            }
        }
        &.links__group--contact{
            @media screen and ( max-width: 718px ) {
                flex-basis: 80%;
            }
            @media screen and ( max-width: 400px ) {
                flex-basis: 70%;
            }
        }
    }
    .links__header {
        font-size: 18px;
        font-weight: 700;

        margin: 0 0 1.3rem 0;
    }
    .link {
        font-size: .87rem;

        padding: 0;
        margin: 1rem 0;

        .link__link-wrap {
            color: currentColor;
            padding: 0;

            &:hover {
                color: @mainColor;
            }
        }
        .link__label {
            font-weight: 300;
        }
    }

    .links__contact {
        display: flex;
        margin-bottom: 5px;
        > i {
            font-size: 18px;
            line-height: 1.2;
            color: @mainColor;  
            padding-right: 7px; 
        }

        .links__phone-number {
            display: flex;
            flex-direction: column; 
            > a {
                color: white; 
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 7px;
            }
        }
    }
}
.layout__column--aside.leftcol {
    @media screen and (max-width: 767px) {
        display: block !important;
        padding: 1rem !important;
    }

    .box {

        @media screen and (max-width: 480px) {
            display: block !important;

            &#box_filter {
                margin-bottom: 0;

                .boxhead {
                    display: none;
                }

                .innerbox {
                    .group-filter {
                        display: none;

                        &#filter_price {
                            display: block;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .boxhead {
            font-size: 1.4rem;
            border-bottom: 0;
        }

        .innerbox {
            .group {
                >h5 {
                    font-size: 17px;
                    padding-bottom: 10px;
                }
            }

            .group-filter {
                width: 100%;
                padding: 0 0 10px;
                border-bottom: 1px solid @borderColor3;

                &:last-of-type {
                    border-color: transparent;
                }

                ul {
                    li {
                        line-height: 1 !important;
                        padding: 3px 0;
                        margin: 2px 0;

                        a {
                            font-size: 12px;
                            line-height: 1.2;
                            color: black;

                        }

                        &.selected {
                            background-color: @bgColor  !important;

                            &.fa {
                                &::before {
                                    content: '\e939';
                                    font-family: Frino;
                                    color: @mainColor;
                                }
                            }

                            a {
                                position: relative;

                                &::before {
                                    content: '';
                                    display: block;
                                    width: 8.5px;
                                    height: 8.5px;
                                    background: @mainColor;
                                    position: absolute;
                                    top: 3px;
                                    left: 13.5px;
                                    line-height: 1;
                                }

                                img {
                                    background: transparent !important;
                                    position: relative;
                                    width: 15px !important;
                                    height: 15px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


body {
    &.shop_product_list {
        #box_menu {
            display: none;
        }
    }

    &.shop_product_producer {
        #box_menu {
            display: block;
        }
    }
}
form.search-form {
    margin: 0;
    flex-grow: 1;

    max-width: 590px;

    padding: 0 10px;

    .rwd & {
        @media screen and ( max-width: 1100px ) {
            max-width: 350px;
        }
        @media screen and (max-width: 979px) and (min-width: 768px) {
            top: auto;
        }
        @media screen and ( max-width: 860px ) {
            box-sizing: border-box;
            padding: 0 2rem;
        }
        @media screen and ( max-width: 767px ) {
            display: none;
    
            &.active {
                display: block;
            }
    
            max-width: 100%;
            width: 100%;
            padding: 1rem 0 0; 
        }
    } 

    &.display-search {
        display: block!important;
    }

    .search__input-area {    
        height: 50px;
        border: 0;
        border-top-left-radius: 25px; 
        border-bottom-left-radius: 25px;  

        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;

        .search__btn-search { 
            width: 160px;
            height: 50px;
            background-color: @mainColor;
            text-transform: uppercase;  
            font-weight: bold;  
            font-size: 14px;
            color: white;
            border-radius: 5px; 

            transition: color 0.3s ease-in-out, border 0.3s ease-in-out, background 0.3s ease-in-out;
            
            @media screen and (max-width: 860px) {
                width: 115px;
            }
            .button__icon { 
                padding-right: 10px;
                font-size: 16px;
                line-height: 1;
                &::before {
                    font-weight: bold;
                }
            }
            &:hover {
                background-color: white; 
                color: @mainColor;
                border: 1px solid @mainColor;
            }
        }
    }

}
.secondMenu {
    &__container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap; 
        padding: 0 5px;

        .secondMenu__item {
            flex-basis: calc( (100% / 6) - 6px);
            min-width: 170px; 
            height: 95px;
            max-height: 95px;
            margin: 0 3px 10px 0;
 
            border-top-left-radius: 5px;
            border-top-right-radius: 5px; 

            position: relative;

            transition: .4s ease-in-out;

            &::after {
                content: '';
                width: 100%;
                height: 4px;
                background: @mainColor;

                position: absolute;
                bottom: 0;
                left: 0;

                transition: .4s ease-in-out;
            }

            &:hover {
                opacity: 1;
                &::after {
                    height: 100%;
                    border-radius: 5px 5px 0 0;
                }
            }

            @media screen and (min-width: 1201px) {
                &:first-of-type { 
                    margin: 0 3px 0 0;
                }
                &:last-of-type { 
                    margin: 0 0 0 3px; 
                }
            }

            @media screen and (max-width: 1200px) {
                flex-basis: calc( (100% / 3) - 6px);
            }
            @media screen and (max-width: 587px) {
                flex-basis: calc( (100% / 2) - 6px);
            }
            @media screen and (max-width: 414px) {
                min-width: 120px;
            }



            background: black;
            color: white;
            
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .secondMenu__icon {
                width: 32px;
                height: 32px;
                border: 2px solid white;
                border-radius: 50%;
                font-weight: bold;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;

                z-index: 1;
    
                > .icon--custom {
                    font-size: 16px;  
                    line-height: 1;
                }
            }

            &:first-of-type {
                .secondMenu__icon {
                    > .icon--custom {
                        font-size: 10px;  
                    }
                }
            }

            .secondMenu__title {
                margin: 10px 0 0 0;

                font-size: 16px;
                text-transform: uppercase;

                z-index: 1;

                @media screen and (max-width: 414px) {
                    font-size: 14px;
                }
            }
        }


    }
}
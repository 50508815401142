.radio-wrap {
    label {
        &::after {
            top: 1px;
            left: 1px;
        }
    }
}

#cart-options {
    .delivery-container {
        .delivery {
            display: flex;
            //flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            @media screen and (max-width: 979px) {
                flex-wrap: wrap;
            }

            .name {
                display: flex;
                //flex-wrap: wrap;
                align-items: center;
            }
        }
    }
}
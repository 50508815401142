.rental {
    &__row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
       // justify-content: center;
        width: 100%;

        @media screen and (max-width: 979px) {
            justify-content: center;
        }
    }

    &__product {
        flex-basis: calc(100% / 2);
        width: 100%;
        max-width: 580px;
        min-width: 300px;
        padding: 1rem;
        margin: 0 0 1rem 0;

        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .rental__product-img { 
            flex-basis: 100%;
            flex: 1;
            width: 100%;
            min-width: 245px;
        }
  
        .rental__product-text {  
            flex-basis: 100%; 
            flex: 1;

            padding: 10px; 

            .rental__title { 
                font-size: 18px;
                font-weight: bold;
            } 

            .rental__description {
                border-top: 1px solid black;
                padding: 1rem 0;
            }
        } 
    }
} 
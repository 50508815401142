.module.module--newsletter {
    flex-basis: 35%;
    padding: 2rem 2.5rem 0 0;
    .module__header {
        margin: 0 0 1rem 0;
        padding: 0;
        border-top: 0;
        &::before, &::after {
            display: none;
        }
        .module__slogan,
        .module__title {
            font-size: 1.45rem; 
            font-weight: 300;
            line-height: 1.2em; 

            margin: 0;
            padding: 0;
            background: black;

        }
        .module__slogan {
            
        }
        .module__title {
            color: white;
            font-size: 1.9rem;
        }
    }

      
    .newsletter__form {
        display: flex;
        flex-wrap: wrap;
        
        justify-content: space-between;
        align-items: center;
        align-content: center; 

        .module__header {
            flex-basis: 100%;
            flex-grow: 0;
            max-width: 300px;
        }
        .newsletter__fieldset {
            flex-basis: 100%;
            flex-grow: 1;

            background: white;
            min-height: 45px;

            text-align: left;

            border-top-left-radius: 50px;
            border-bottom-left-radius: 50px;

            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            position: relative;

            .newsletter__input {
                min-height: 45px;

                border: 0;
                border-top-left-radius: 50px;
                border-bottom-left-radius: 50px;
 
                margin: 0;
                padding: 0 2rem;

                @media screen and (max-width: 480px) {
                    padding: 0 1rem;
                    font-size: 12px;
                }
            }
              
            .newsletter__submit {
                background: @mainColor;
                color: white;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;

                height: 45px;
                padding: 0 30px;
                
                border: 1px solid @mainColor;
                transition: .4s ease-in-out;

                border-radius: 5px;
                position: absolute;
                right: 0;
                top: 0;

                &:hover {
                    background: white;
                    color: @mainColor;
                }

                @media screen and (max-width: 480px) {
                    padding: 0 15px;
                }
            }
   
        }
        
        .module__header,
        .newsletter__fieldset { 
            @media screen and ( max-width: 1200px) {
                flex-basis: 100%;
                flex-grow: 1;
            }
        } 


        .newsletter__term {
            display: flex;
            align-items: center;
            padding: 1.5rem 0 .5rem;

            .term__checkbox {
                position: relative;
                margin-bottom: 0;
                border: 1px solid white;
                background: black;
                box-shadow: none;
                width: 20px;
                height: 20px;
                -webkit-appearance: none;
                margin-right: 8px;
                padding: 9px;

                &::before {
                    content: '';
                    line-height: 1;
                    width: 12px;
                    height: 12px;
                    
                    position: absolute;
                    top: calc(50% - 6px);
                    left: calc(50% - 6px);
                    
                    background: @mainColor;
                    opacity: 0;
                    transition: opacity 0.2s ease-in-out;
                }

                &:checked::before {
                    opacity: 1;
                }
            }

            .term__label {
                font-size: 12px;
                font-weight: 300;
                line-height: 1.5;
                color: white;

                .term__label-link {
                    font-weight: bold;
                    border-bottom: 1px solid white;
                    color: white;
                }
            }
        }
    }
}
  
* {
    box-sizing: border-box;
}
.layout {
    
    &__wrapper {
        width: 100%;
        max-width: 1180px!important;
        margin: 0 auto;

        @media screen and (max-width: 1200px) {
            padding: 0 20px;
        }
    }
   
    .layout__content {
        // margin: 0.5rem;

        &.layout__content--bordered {
            border: 1px solid @textColor;
        }
        &.layout__content--right {
            text-align: right;
        }
    }
 
    &.layout--columns {
        display: flex;  
        flex-wrap: wrap;
        
        margin: 0 -2rem;

        &.layout--align-middle {
            align-items: center;
        }

        &.layout--content {
            margin: 0 -1rem;
        }
        
        .layout__column {
            position: relative;
    
            flex-basis: 0;
            flex-grow: 1;
            
            box-sizing: border-box;
            max-width: 100%;
            width: 100%;
            min-height: 1px;

            &.layout__column--aside {
                flex-basis: 15rem;
                flex-grow: 0;
                padding: 5rem 1rem 0;

                .box {
                    width: 100%;
                    margin-bottom: 2rem;
                    padding: 0 3px;
                }
            }
            &.layout__column--center {
                padding: 0 1rem;
                margin: 0;
            }
            &.layout__column--full {
                width: auto;
                max-width: unset;
                padding: 0;
                margin: 0 -1rem;
            }
        }
    }
    &.layout--tiles {
        display: flex;
        flex-wrap: wrap;

        align-items: stretch;

       // margin: 0 -0.2rem;

        &.layout--tiles--half {
            margin: 0 -0.5rem;
        }
        
        .layout__tile {
            position: relative;
    
            flex-basis: 0;
            flex-grow: 1;
            
            box-sizing: border-box;
            max-width: 100%;
            width: 100%;
            min-height: 1px;

            padding: 0.2rem; 

            &.layout__tile--quarter {
                flex-basis: calc( ~'100% / 4' );
                flex-grow: 0;
            }
            &.layout__tile--3 {
                flex-basis: calc( ~'100% / 3' );
                flex-grow: 0;
            }
            &.layout__tile--half {
                flex-basis: calc( ~'100% / 2' );
                flex-grow: 0;

                padding: 0.5rem; 

                @media screen and (max-width: 480px) {
                    flex-basis: 100%;
                }
            }
        }
    } 
}
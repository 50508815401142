.footer {
    min-height: 465px;
    background: black;
    margin: 2.5rem 0 0;
    padding: 0;
}

 
#shoper-foot { 
    display: none;
}
 .button {
    border-radius: 5px;
    font-weight: bold;
    font-size: 12px; 
    text-transform: uppercase; 
    border: 1px solid transparent;
    transition: color 0.3s ease-in-out, border 0.3s ease-in-out, background 0.3s ease-in-out;
    &--solid {
        background: @mainColor;
        color: white;
        padding: 9px 18px;
        border: 1px solid @mainColor;
        

        &:hover {
            background: white;
            color: @mainColor;
        }
    }
    &--addtobasket { 
        background: @mainColor; 
        color: white;  
        text-transform: uppercase;
        font-size: 13px;
        line-height: 1;
        border-radius: 0;
        border: 0;
        width: 100%;
    }

 }
.sort-and-view { 
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    margin-bottom: 2.2rem;
    .sort-label {
        font-size: 16px;
    }

    .sortlinks {
        padding-right: 15px;
    }

    .sort-by-name, .sort-by-price { 
        margin-left: 12px; 
        a {
            font-size: 16px;
        }
        &.sort--ASC { 
            a {
                position: relative;
                display: inline-block;
                padding-right: 16px; 
                color: @mainColor;
                &::after {
                    content: "\e914";
                    position: absolute;
                    top: 15%;
                    right: 0;
                    color: @mainColor;
                    font-family: 'frino';
                    font-weight: 400;
                    font-style: normal;
                    font-size: 16px;
                    line-height: 1;
                    letter-spacing: normal; 
                    text-transform: none; 
                    display: inline-block;
                    vertical-align: middle;
                    white-space: nowrap;
                    word-wrap: normal;
                    direction: ltr;
                    -webkit-font-feature-settings: 'liga';
                    font-feature-settings: 'liga';
                    -webkit-font-smoothing: antialiased;
                }
            }
        } 
        &.sort--DESC {
            a {  
                position: relative;
                display: inline-block;
                padding-right: 16px;
                color: @mainColor;
                &::after {
                    content: "\e90D";
                    position: absolute;
                    top: 15%;
                    right: 0;
                    color: @mainColor;
                    font-family: 'Frino';
                    font-weight: 400;
                    font-style: normal;
                    font-size: 16px;
                    line-height: 1;
                    letter-spacing: normal;
                    text-transform: none;
                    display: inline-block;
                    vertical-align: middle;
                    white-space: nowrap;
                    word-wrap: normal;
                    direction: ltr;
                    -webkit-font-feature-settings: 'liga';
                    font-feature-settings: 'liga';
                    -webkit-font-smoothing: antialiased;
                }
            }
        }
    }
}
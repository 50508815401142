.header {
    @media screen and ( max-width: 767px ){
        padding: 10px 0 0!important;
    }
    &--site {
        background: black;
        color: white;
        padding: .6rem 0 1.5rem;

        @media screen and (max-width: 1200px) {
            padding: 0 0 1rem;
        }
    } 

    .header__container {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;

        padding: 0 10px;

        @media screen and (max-width: 1200px) {
            padding:  0 20px;
        }
        
        .rwd & {
            @media screen and ( max-width: 767px ) {
                flex-direction: column;
                padding: 0;
                // flex-wrap: wrap;
                justify-content: center;
                // .logo{
                //     // flex-basis: 100%;
                //     // margin: 10px 0;   
                // }
                .user-panels{
                    display: none;
                    margin: 10px 0;  
                    width: 100%;
                }
            } 
        }
    }
}
form {
    label,
    input,
    button,
    select,
    textarea {
        box-sizing: border-box;

        font-family: @fontFamily;
        font-size: 1rem;
        line-height: 1.4em;

        padding: 0.7em 0.7em 0.5em;
        margin: 0;

        &:focus {
            outline: none;
            box-shadow: none!important;
        }
    }

    button {
        &:focus { 
            outline: none;
            box-shadow: none!important;
        }
    }
 
    textarea,
    input {
       // -webkit-appearance: none;
        border: 1px solid @textColor;
    }
}

#filter_price {
    #slider-range {
        &.ui-widget.ui-widget-content {
            border: 1px solid #666666;
        }
        &.ui-slider {
            width: 80%%;
            height: 1px;
            margin: 10px 5% 15px;
            .ui-slider-range {
                background: #666666;
            }
            .ui-slider-handle {
                background: @mainColor; 
                border: 1px solid @mainColor;
                height: 15px;
                width: 15px;
                margin-top: -4px;
            }
        } 
    }

    .filter_price_preview { 
        display: flex;
        justify-content: space-between; 
        width: 100%;
        max-width: 135px;
        font-size: 12px;
        padding: 0 0 0 10px;
    }

    .priceinput {
        padding-top: 0!important;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
    }
}


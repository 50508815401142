.module__header {
    margin: 2.3rem 0 2.3rem 0;
    position: relative;

    display: flex;
    justify-content: flex-start;

    &::before {
        content: '';
        width: 100%;
        max-width: 70px; 
        height: 1px;
        background: @mainColor;
        position: absolute;
        top: 50%;
        left: 3px; 
        z-index: 0;
    }
    &::after { 
        content: ''; 
        width: 100%;
        height: 1px;
        background: @mainColor; 
        position: absolute;
        top: 50%;
        left: 0; 
        z-index: -2; 
    } 
    .module__title {  
        margin: 0; 
        padding: 0 15px 0 80px; 
        font-size: 32px;  
        font-weight: 300; 
        line-height: 1.2;
        background: white;

        position: relative; 

        z-index: -1;
    }
}  

  
.user-panels {
    flex-grow: 1;
    max-width: 190px;
      
    .user-panel { 
        &__container {
            display: flex; 
            align-items: center;
            justify-content: space-between; 
            .basket__icon {  
                color: white;
                position: relative;
                .button__icon { 
                    font-size: 2rem;
                }
                .basket__count {
                    position: absolute; 
                    top: -10px;
                    right: -10px;
                    background: @mainColor; 
                    font-size: 14px;
                    font-weight: bold;
                    border-radius: 50%;
                    width: 25px;
                    height: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center; 
                    line-height: 1;
                }
            }
            .user-panel__spacer {
                width: 1px;
                height: 35px;
                background:  @white50;
            }
            .user-panel__label {
                color: white;
                font-weight: bold; 
                font-size: 1.2rem;
            }
        }
    }
}
#leasingContainer {
    display: none!important;
}

#ceneo-widget {
    z-index: 9!important;
    top: 215px!important;
}

.social-sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: fixed;
    top: 50%;
    right: 0;
    
    @media screen and (max-height: 820px) {
        top: 60%;
    }
    
    @media screen and (max-width: 767px) {
        display: none;
    }
    &__link {
        width: 50px;
        height: 50px;
        font-size: 22px;
        color: white;

        display: flex;
        align-items: center; 
        justify-content: center;

        transition: .4s ease-in-out;
        &:hover { 
            width: 100px;
            color: white;
        }
        &--facebook  {
            background: #0054a6;
        }
        &--youtube {
            background: #ed1c24;
        }
        &--instagram {
            background: #ce2d95;
        }
    } 
}
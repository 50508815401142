.modules {
    display: flex;
    flex-wrap: wrap;
    width: auto;

    align-content: stretch;
    align-items: center;

   // margin: 0.3rem -2.3rem;

    overflow: hidden;

    &.modules--header {
        // display: none;
    }
    &.modules--bottom {
        padding-bottom: 2.5rem;
    }

    .module {
        position: relative;

        flex-basis: 0;
        flex-grow: 1;
        
        box-sizing: border-box;
        max-width: 100%;
        width: 100%;
        min-height: 1px;

        padding: 0.3rem;

 

        &.module--thirds {
            flex-basis: calc( ~'100% / 3' );
            width: calc( ~'100% / 3' );
            max-width: calc( ~'100% / 3' );
            flex-grow: 1;
            @media screen and (max-width: 767px) {
                max-width: unset;
            }
        }
        &.module--quarter {
            flex-basis: calc( ~'100% / 4' );
            width: calc( ~'100% / 4' );
            flex-grow: 1;
            
            .rwd & {
                @media screen and ( max-width: 690px ) and ( min-width: 360px ) {
                    flex-basis: 50%;
                    width: 50%;
                    max-width: 50%;
                }
                @media screen and ( max-width: 359px ) {
                    flex-basis: 100%;
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
}
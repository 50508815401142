#box_productfull { 
    padding: 0 15px; 

    .productfull__info-row {
        display: flex;  
        justify-content: space-between;

        @media screen and ( max-width: 979px ) { 
            flex-direction: column;
        }
    }
} 
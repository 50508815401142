.logo {
    width: 100%;
    max-width: 290px;

    @media screen and (max-width: 979px) {
        max-width: 240px;
        text-align: center;
    }
    @media screen and (max-width: 767px) {
        padding: 0 0 15px 0;
    }
     
    .logo__link-wrap {
        &:hover {
            opacity: 1;
        }
        span,h1 { 
            margin: 0;
            @media screen and ( max-width: 767px ) {
                    text-align: center;
            }
        }
    } 
    .logo__image {
        position: relative; 
        width: 100%;
        max-width: 280px;
        @media screen and ( max-width: 767px ) {
            width: 70%;
        }
    }
}
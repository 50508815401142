.productfull__info {
    border: 1px solid  @borderColor2;
    border-radius: 3px; 
    padding: 23px 20px;
    flex-basis: 53%;
    @media screen and (max-width: 1200px) {
        flex-basis: 47%;
    }

    .productdetails {
        display: flex; 
        align-items: center;
        justify-content: space-between;
        padding: 0 7% 0 7%;
        min-height: 60px;

        @media screen and (max-width: 480px) {
            flex-direction: column;
            align-items: flex-start;
        }

        &__question, &__brand {
            padding: 0 0 15px 0;
        }

        &__question {
            .question {
                font-size: 14px;
                display: flex;
                align-items: center;
                &::before {
                    content: '\e92f'; 
                    font-family: Frino;
                    line-height: 1;
                    color: @mainColor;
                    font-size: 18px;
                    padding-right: 5px;
                }
            }
        }
    }
      
    .productavailability {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 7%;
        margin: 10px 0 0;
        &__box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-basis: 48%;
            border: 1px solid @borderColor2;
            border-radius: 3px;
            margin: 20px 0 0;
            padding: 10px 5% 0px 5%;
            min-height: 65px;

            position: relative;

            &::before {
                font-family: Frino;
                font-size: 28px;
                color: @mainColor;
                background: white;
                padding: 0 8%;

                position: absolute;
                top: -10px;
                left: 7%;
            }

            &.productavailability__availability {
                &::before {
                    content: '\e776';
                }
            }
            &.productavailability__delivery {
                &::before {
                    content: '\e9db';
                }
            }

            .first {
                font-size: 12px;
            }
            .second {
                font-size: 14px;
                font-weight: bold;
                color: @mainColor;
            }
        }
        
    }

    .bottomborder {
        padding: 0 7%;

        .basket {
            margin-top: 0!important;
            .form-basket {
                margin-top: 10px!important;
                .stocks {
                    .stock-row {
                        .stock-label {
                            label {
                                font-size: 14px!important;
                                line-height: 1.2!important;
                                padding: 10px 0;
                            }
                        }
                        .stock-options {
                            .option_select {
                                position: relative;
                                &::after {
                                    content: '\e910';
                                    font-family: Frino;
                                    font-size: 18px;
                                    font-weight: bold;
                                    color: @mainColor;

                                    position: absolute;
                                    top: 50%;
                                    right: 10px;
                                    transform: translate(-50%, -50%);
 
                                    pointer-events: none;
                                }
                                &:hover {
                                    &::after {
                                        color: white;
                                    }
                                    select {
                                        background: @mainColor;
                                        color: white;
                                    }
                                }
                                select {
                                    border-radius: 3px;
                                    border-color: @borderColor2;
                                    font-size: 14px;
                                    line-height: 1.2;
                                    background: none;
                                }
                            }
                        }
                    }
                }

                .addtobasket-container {
                    display: block;
                    .quantity_wrap {
                        width: 45%!important;
                        flex-basis: 45%;

                        display: flex;
                        align-items: center;

                        margin-top: 5px;

                        .number-wrap {
                            font-size: 14px;
                            font-weight: bold;
                            text-align: center;
                            text-transform: uppercase;
                            #incdec {
                                display: flex;
                                align-items: center;

                                .product-quantity {
                                    height: 50px;
                                    min-height: 50px;
                                    width: 50px!important;
                                    min-width: 50px;
                                    margin: 0 3px;

                                    text-align: center;
                                    color: black;

                                    border-color: @borderColor2;
                                    border-radius: 4px;

                                    -webkit-appearance: textfield;
                                    -moz-appearance: textfield;
                                    appearance: textfield;
                                }

                                #down, #up {
                                    width: 50px;
                                    height: 50px;
                                    border: 1px solid @borderColor5;
                                    border-radius: 3px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    cursor: pointer;
                                    user-select: none;

                                    transition: background-color 0.3s linear;

                                    &:hover {
                                        background: @mainColor;
                                        color: white;
                                        border-color: @mainColor;
                                    }
                                }
                            }
                        }
                        .unit {
                            color: black!important;
                            margin: 0 5px;
                        }
                    }
                    .button_wrap {
                        min-width: 55%!important;
                        width: 55%!important;
                        flex-basis: 55%;

                        display: flex;
                        align-items: center;

                        margin-top: 5px;

                        @media screen and (max-width: 480px) {
                            width: 100%!important;
                        }
                        .addtobasket {
                            min-height: 50px!important;
                            border-radius: 3px;
                            text-transform: uppercase;
                            letter-spacing: .5px;
                            font-weight: bold;
                            font-size: 12px;
                            text-align: center;

                            display: flex;
                            align-items: center;
                            justify-content: center;

                            position: relative;
                            &::before {
                                content: '\e918';
                                font-family: Frino;
                                font-size: 24px;
                                font-weight: normal;
                                color: white;

                                padding: 0 5px 0 0;
                            }

                            &:hover {
                                background-color: white;
                                border-color: @mainColor;
                                color: @mainColor;

                                &::before {
                                    color: @mainColor;
                                }
                            }
                        }
                    }
                }
            }
        }
        
        .founding {
            display: flex;
            align-items: center; 
            justify-content: space-between;
            margin: 1.2rem 0;

            @media screen and (max-width: 480px) {
                flex-direction: column;
                align-items: flex-start;
            }
            &__header {
                flex-basis: 33%;
                font-size: 13px;
            }
            &__group {
                display: flex;
                align-items: center;
                justify-content: space-between; 
                flex-basis: 66%;

                .installment { 
                    .leaselinkCalucalteButtonContainer {  
                        padding: 0 10px 0 10px; 
                        a {
                            padding: 15px 25px 15px 10px!important;
                            font-size: 8px!important;
                            line-height: 1.2;
                        }
                    }
                }
            }
        }
    }

    .productdetails-more-details {
        border: 0!important;
        padding: 0!important;
        margin-top: 0!important;
        line-height: 1;
    }
    .phone-order {
        display: flex;
        justify-content: center;
        align-items: center;

        min-height: 50px;

        background: black;
        color: white;
        font-size: 14px; 
        font-weight: bold;

        border-radius: 3px;

        @media screen and (max-width: 650px) {
            flex-direction: column;
        }

        &__header {
            margin: 0 7px;
        }

        &__number {
            padding: 10px 0;
            margin: 0 7px;
            > a {
                color: white;

                display: flex;
                align-items: center;
                &::before {
                    content: '\e973';
                    font-family: Frino;
                    font-size: 17px;
                    color: @mainColor;
                }
            }
        }

    }
}
.products--list {
    margin: 2rem 0;
    .product {
        .prodimage {
            img {
                max-height: 190px;
            } 
        }

        .addtobasket {
            text-transform: uppercase;
            font-weight: bold; 
            line-height: 1;
            border: 0;
            padding: 10px 20px;

            &:hover {
                background: black;
            }
        }
    }
}
.wrap{
    &.rwd{
        .products-list{
            .products-list__menu{
                @media screen and ( max-width: 767px ) {
                    display: none;
                }
            }
        }
        .innermain {
            &.w-100{
                @media screen and ( max-width: 1030px ) {
                    margin: 0 5px;
                }
            }
        }
    }
}

.module--slider {
    padding-top: 0!important;
    .pageslider { 
        .slider-nav {
            display: flex;
            justify-content: flex-end;
 
            text-align: right; 
            padding-right: 25px;
            li {
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: center;

                color: white;
                background: black;

                width: 35px;
                height: 35px;
                border-radius: 5px;

                margin: 0 4px;

                line-height: 1;
                font-size: 16px; 
                font-weight: bold;

                &.active {
                    background: @mainColor;
                    color: white;
                }
            }
        }
 
        .ps-arrow-left, .ps-arrow-right { 
            background: white!important;  
            color: black;
            visibility: visible!important;
            top: calc(50% - 3.5rem);

            display: flex; 
            justify-content: center; 
            align-items: center;

            width: 2em!important;
            height: 12em!important;

            transition: .3s ease;

            &:hover {
                background: @mainColor!important;
                color: white;
            }
        }  

        .ps-arrow-left {
            left: 0!important; 
            &::before {
                content: '\e911';
                font-family: Frino;
                font-size: 18px;
                font-weight: bold;
            }
        }

        .ps-arrow-right {
            right: 0!important;
            &::before {
                content: '\e912';
                font-family: Frino;
                font-size: 18px;
                font-weight: bold;
            }
        }


    }
}
.paginator-wrapper {
    text-align: right;

    @media screen and ( max-width: 767px ) {
        margin: 2.2rem 1rem 2rem;
    }
    @media screen and (max-width: 530px) {
        margin: 0 1rem 2rem;
    }
    &--top {
        @media screen and (max-width: 530px) {
            display: none;
        }
    }
    .paginator {
        margin: 0;
        padding: 0;

        li {
            margin: 0 2px; 
            height: 34px; 
            min-width: 34px;
            border: 1px solid black;
            border-radius: 5px;
 
            display: inline-flex;
            justify-content: center;
            align-items: center;
            line-height: 1;  

            transition: .5s ease;

            > a, span {
                border: 0; 
                padding: 10px;
                color: black;
                font-size: 14px;
                font-weight: bold;
            }

            &.first, &.last {
                border-color: @mainColor;
                
                min-width: 60px;
  
                > a, span {
                    color: @mainColor;
                    padding: 10px 20px;
                }
            }
            
 
            &:hover, &.selected { 
                background: @mainColor;
                border-color: @mainColor;
                > a, span {
                    color: white;
                    opacity: 1;
                }
            }
        }
    }
} 
.box {
    .product-box-head {
        display: flex; 
        align-items: center;
        justify-content: space-between;  
        border-bottom: 0;
        margin: 20px 0;
        line-height: 30px; 
        @media screen and (max-width: 480px) {
            flex-direction: column;
            align-items: flex-start;
        }
        &__name {
            .name {
                font-size: 30px; 
                line-height: 1.2;
            }
            .code {
                font-size: 12px;
            }
        }
        &__price {
            .price-name {
                font-size: 14px;
            }
            .main-price {
                font-size: 30px;
                color: @mainColor;
                font-weight: 900;
                text-transform: uppercase;
            }
        }
    }
}
.products--grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 2.2rem 0 1rem 1rem;
    width: 100%;
    @media screen and ( max-width: 979px ) { 
        justify-content: space-around;
        margin: 2.2rem 0 1rem;
    }
    @media screen and ( max-width: 490px ) { 
        justify-content: center;
    }
    .product {
        flex-basis: calc((100% / 3) - 30px);
        flex-grow: 0;
        width: 100%;
        max-width: 280px; 
        max-height: 375px;
        margin: 0 15px 30px 15px;

        border-color: @borderColor4;
        border-bottom: none !important;
        padding: 0!important;

        transition: .5s ease-in-out; 
        position: relative;
        @media screen and ( max-width: 979px ) {
            flex-basis: calc((100% / 2) - 6px);
        }
        @media screen and ( max-width: 490px ) {
            flex-basis: calc((100%) - 6px);
        }
        &:hover { 
            border-color: black;
            .product__addtobasket {
                .addtobasket {
                    background: black;
                }
            } 
            .product__image {
                .product__thumbnail {
                    transform: scale(1.1);
                }
            }
            
            
        }

        &__image {
            position: relative;
            width: 100%; 
            max-width: 100%;
            height: 250px;
            display: flex; 
            justify-content: center;
            align-items: center;

            padding: 0;
            margin: 0;
            
            .product__thumbnail {  
                max-width: 230px;
                max-height: 150px;

                transition: all 0.5s ease;
            }
        }

        &__header {
            @media screen and ( max-width: 767px ) { 
                height: 150px;
            }
            .product__title {
                margin: 0;
                text-align: center !important;
                font-size: 17px;
                padding: 0 .5rem;
                line-height: 1.2;
                max-height: 80px;
                overflow: hidden;

                @media screen and (max-width: 1200px) {
                    font-size: 16px;
                }
                @media screen and (max-width: 979px) {
                    font-size: 14px;
                }
            }
        }

        &__actions {
            display: flex; 
            align-items: center;
            justify-content: space-between;

            position: absolute;
            bottom: 0; 
            left: 0;
            
            width: 100%;

            .product__price.price {
                margin: 0; 
                
                background: black;
                padding: 3px 0; 
 
                flex: 58%;
                flex-grow: 1;

                color: white;

                min-height: 30px;
                @media screen and ( max-width: 1050px ) {
                    flex-basis: 45%;
                }
                @media screen and ( max-width: 767px ) {
                    flex-basis: 40%;
                }
                .price__gross {
                    text-transform: uppercase;
                    display: flex;
                    align-items: baseline;
                    justify-content: center;
                    .price__reduced {
                        font-size: 12px;
                        margin: 0 3px;
                    }
                    .price__current {
                        font-size: 15px;
                        font-weight: bold;
                        margin: 0 3px;
                    }
                }

            }
 
            .product__addtobasket { 
                flex: 42%; 
                @media screen and ( max-width: 880px ) {
                    flex-basis: 100%;
                }
                @media screen and ( max-width: 580px ) {
                    flex-basis: 40%;
                }
                flex-grow: 1;
                min-height: 30px;
                background: @mainColor;
                .addtobasket {
                    min-height: 30px;
                }
            }
        }


    }
}
.rwd.wrap .main{
    &.layout__wrapper{
        @media screen and ( max-width: 767px ) {
            margin: 0 auto !important;
        }
    }
}
#box_productfull {
    .productfull {
        &__gallery {
            @media screen and ( max-width: 979px ) { 
                margin: 0 0 20px;
            }
            &.productimg {
                flex-basis: 45%;
                margin-left: 0;
                @media screen and (max-width: 1200px) {
                    flex-basis: 47%;
                }
                .mainimg {
                    width: 100%;
                    max-width: 510px;
                    height: 390px;
                    display: block;
                    justify-content: center; 
                    align-items: center;
                    background: #fff;
                    border: 1px solid  @borderColor2;
                    position: relative; 
                    overflow: visible;
                    @media screen and ( max-width: 979px ) { 
                        max-width: 100%;
                    }
                    .img-wrapper {  
                        width: 100%;
                        height: 100%; 
        
                        display: flex;
                        justify-content: center; 
                        align-items: center;

                        img {
                            max-height: 385px;
                        }
                    }
                } 
    
                .gallery-navigation {
                    position: absolute; 
                    right: 0;
                    top: 45%;
                    line-height: 1; 

                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    .gallery-left,
                    .gallery-right {
                        
                        display: inline-block;
                        vertical-align: middle;
            
                        width: 30px;
                        height: 30px;
            
                        color: white;
                        font-size: 1.15rem;
                        line-height: 32px;
                        text-align: center;
                        background: @mainColor; 
                        border-radius: 5px;
            
                        margin: 0;
            
                        transition: opacity 0.3s ease-in-out;
            
                        &:hover { 
                            opacity: 0.6;
                        }
                    }

                    .gallery-left {
                        margin-left: -.8rem;
                    }
                    .gallery-right {
                        margin-right: -.8rem;
                    }
                }
    
                .smallgallery {
                    max-width: 520px;
                    margin: 0 0 0 -5px;
                    background: #fff;

                    @media screen and ( max-width: 979px ) { 
                        max-width: 100%;
                    }
    
                    ul {
    
                        li {
                            border: 0;
                            width: 120px; 
                            margin: 0 5px; 
                            position: relative;
    

    
                            a {
                                padding: 0;
                                width: 100%;
                                max-width: 120px;
                                height: 90px;
                                border: 1px solid @borderColor2;

                                display: flex;
                                justify-content: center;
                                align-items: center;

                                &::before {
                                    content: '';
                                     position: absolute; 
                                     z-index: 1;
                                     bottom: 0px; 
                                     left: 0;
                                     height: 3px;
                                     width: 100%;
                                     background: @mainColor;
                                     display: none;
                                 }
          
                                 &:hover {
                                     &::before {
                                         display: block;
                                     } 
                                 }


                                > img {
                                    max-width: 80px;
                                    max-height: 80px;
                                }
                            }
                        }

                        &.with-nav {
                            margin: 0;
                        }
                    }

                    .smallgallery-left, .smallgallery-right {
                        width: 20px;
                        height: 20px; 
                        background: @mainColor;
                        color: white; 
                        font-size: 14px;
                        line-height: 22px;
                        text-align: center;
                        border: 0;
                        border-radius: 5px;
                        transition: opacity 0.3s ease-in-out;
                        &:hover {
                            opacity: 0.6;
                        }
                        
                    }

                    .smallgallery-left {
                        border-right: none;
                    }
                    .smallgallery-right {
                        border-left: none;
                    }
    
                }
            } 
        } 
    }
}

.shop-gallery .shop-gallery-preview {
    padding: .5em 3em;
}
html {
    font-size: @fontSize;
}

body {
    background-color: white;
}

*,
::after,
::before {
    box-sizing: border-box;
}

a {
    transition: opacity 0.3s ease-in-out;
    outline: 0;

    &:hover {
        opacity: 0.7;
    }
}

img {
    max-width: 100%;
    //width: auto;
    //height: auto;  
}

header {
    border-top: 0;
}

::selection {
    background: @mainColor;
    color: @backgroundColor;
    opacity: 1;
}

.select-wrapper {
    position: relative;

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        background: none;
    }
}

.hide {
    display: none;
}


.tableResponsive {
    table {
        height: auto !important;
        width: auto !important;
    }

    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        @media screen and (max-width: 480px) {
            justify-content: center;
        }
    }
}